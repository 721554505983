<template>
     <div class="Page404 text-center">
          <r-e-empty description="升级中，请耐心等待！">
               <el-link @click="$router.push('/')"><i class="el-icon-s-home"></i> 点击返回主页</el-link>
          </r-e-empty>
     </div>
</template>

<script>

    export default {
        name: "Page404",
    };
</script>
<style lang="scss" scoped>
     .Page404 {
          .el-empty {
               width: 100vw;
               height: 100vh;
          }
     }
</style>
